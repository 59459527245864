import { default as accessibilitehcLIuSZqT2Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/accessibilite.vue?macro=true";
import { default as _91slug_93efCFh0z1GjMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/activites/[slug].vue?macro=true";
import { default as editxOwYwp9NRUMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/[id]/edit.vue?macro=true";
import { default as indexfjnsQ65zppMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/[id]/index.vue?macro=true";
import { default as addUjU3K2SKl4Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/add.vue?macro=true";
import { default as index8YxRfisbxLMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/index.vue?macro=true";
import { default as editt8BspPQ6VCMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/[id]/edit.vue?macro=true";
import { default as adda9bPSBT3IvMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/add.vue?macro=true";
import { default as indexS8tVAs2tyvMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/index.vue?macro=true";
import { default as editkTQSbduXYAMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/[id]/edit.vue?macro=true";
import { default as addwhUEeATXu5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/add.vue?macro=true";
import { default as indexUfHE9xEhAVMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/index.vue?macro=true";
import { default as editcSxp9mocdtMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/[id]/edit.vue?macro=true";
import { default as indexj8sFnN5UabMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/[id]/index.vue?macro=true";
import { default as addKZtHQtXq5KMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/add.vue?macro=true";
import { default as index7lFHNYjQ2nMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/index.vue?macro=true";
import { default as editVZA7ALrpngMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/[id]/edit.vue?macro=true";
import { default as addLehpBAFwd5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/add.vue?macro=true";
import { default as indexClxNKuT3OjMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/index.vue?macro=true";
import { default as editdm7ylvRDiGMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/[id]/edit.vue?macro=true";
import { default as indexeD4D5Vii6WMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/[id]/index.vue?macro=true";
import { default as add9HHD27fhHuMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/add.vue?macro=true";
import { default as indextK4ME66pTuMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/index.vue?macro=true";
import { default as editDBeYVegX5gMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/testimonials/[id]/edit.vue?macro=true";
import { default as indexXJdDqn4578Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/testimonials/index.vue?macro=true";
import { default as indexPw4LVkRxViMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/index.vue?macro=true";
import { default as benevoles_45informationseAt3pBeti7Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/benevoles-informations.vue?macro=true";
import { default as benevoless8bvfVYiCRMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/benevoles.vue?macro=true";
import { default as dateshbEfBLqt6eMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/dates.vue?macro=true";
import { default as index4HtDZuxH9PMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/index.vue?macro=true";
import { default as informationsFvgUTWVKYvMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/informations.vue?macro=true";
import { default as lieuxXdHiKDlgRjMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/lieux.vue?macro=true";
import { default as responsablesRavWE8X64OMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/responsables.vue?macro=true";
import { default as title6Lfdcb1uQXMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/title.vue?macro=true";
import { default as trouver_45des_45benevoleskihO3AppwJMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/trouver-des-benevoles.vue?macro=true";
import { default as visuel6UKVcn4jVLMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/visuel.vue?macro=true";
import { default as indexmbDBkMp6V4Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/index.vue?macro=true";
import { default as notesH3FRxWzAM0Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/notes.vue?macro=true";
import { default as editWZ3vzlFidXMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/edit.vue?macro=true";
import { default as index2yQvvE5id4Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/index.vue?macro=true";
import { default as addo2WijHjgZqMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/missions/add.vue?macro=true";
import { default as indexAO5LT1MvRrMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/index.vue?macro=true";
import { default as invitationse73IXtq2gMMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/other/invitations.vue?macro=true";
import { default as logsQS9YO177ovMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/other/logs.vue?macro=true";
import { default as indexfCTjz88VK5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/participations/index.vue?macro=true";
import { default as ressourcesvUWMI4YH9EMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/ressources.vue?macro=true";
import { default as emailscWVpbAGHGjMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/emails.vue?macro=true";
import { default as generalAtcCTWLeqgMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/general.vue?macro=true";
import { default as messagesqQVdHWuthrMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/messages.vue?macro=true";
import { default as editdiPKMqfDZ8Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/[id]/edit.vue?macro=true";
import { default as pending_45items98DBXMb0LUMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/[id]/pending-items.vue?macro=true";
import { default as addTk5Sits13dMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/add.vue?macro=true";
import { default as indexiI9WegPmTyMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/index.vue?macro=true";
import { default as trafic_45entrantUufkcfHja5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/api-engagement/trafic-entrant.vue?macro=true";
import { default as trafic_45sortantek7GJgvYFJMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/api-engagement/trafic-sortant.vue?macro=true";
import { default as conversionskaDxwiUk2DMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/conversions.vue?macro=true";
import { default as missions_45a_45validerEMM74da7MDMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/missions-a-valider.vue?macro=true";
import { default as missions_45perimees0AIHMvcokdMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/missions-perimees.vue?macro=true";
import { default as organisations_45a_45validerBnJhhJGCL6Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/organisations-a-valider.vue?macro=true";
import { default as indexw1lPop069dMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/index.vue?macro=true";
import { default as indicateurs_45clesqp8lV5I7VMMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/indicateurs-cles.vue?macro=true";
import { default as missions1CfmgvWK9YMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/missions.vue?macro=true";
import { default as index1MsJAEItGvMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/index.vue?macro=true";
import { default as missions_45perimeesbtXLYLN6eXMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/missions-perimees.vue?macro=true";
import { default as participations_45a_45valider7CiQ6u28ioMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/participations-a-valider.vue?macro=true";
import { default as participations_45refusees_45annuleesVMV1MBqXgQMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/participations-refusees-annulees.vue?macro=true";
import { default as participationsZW5MVEtCO6Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/participations.vue?macro=true";
import { default as placesdfvC2ceUVIMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/places.vue?macro=true";
import { default as utilisateurspgTTQsKFVVMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/utilisateurs.vue?macro=true";
import { default as edit9r5yyIikK9Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/[id]/edit.vue?macro=true";
import { default as adddZU8G2H7NeMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/add.vue?macro=true";
import { default as indexYhI5cH6f79Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/index.vue?macro=true";
import { default as temoignagesCkeUsDDlAlMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/temoignages.vue?macro=true";
import { default as editfJ9w5mlQ7hMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/[id]/edit.vue?macro=true";
import { default as indexeDsX6CtI8ZMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/[id]/index.vue?macro=true";
import { default as indexecUEHsRlHOMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/index.vue?macro=true";
import { default as benevolesNy0vh6HAEJMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/benevoles.vue?macro=true";
import { default as indexlCS2lsN7v3Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/index.vue?macro=true";
import { default as responsablesQdUggZ5g0FMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/responsables.vue?macro=true";
import { default as charte_45reserve_45civiqueVIMzIfzR9sMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-reserve-civique.vue?macro=true";
import { default as conditions_45generales_45d_45utilisation6Xcnk93h3cMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/conditions-generales-d-utilisation.vue?macro=true";
import { default as indexySwUgkt2cEMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/dashboard/index.vue?macro=true";
import { default as _91slug_93DkHUssqcAxMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/departements/[slug].vue?macro=true";
import { default as _91slug_93SFTF9Lbg8LMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/domaines-action/[slug].vue?macro=true";
import { default as en_45ce_45momentfmss0rgCsAMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/en-ce-moment.vue?macro=true";
import { default as indexKo6eviulkbMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/index.vue?macro=true";
import { default as indexwbwf3pPxzCMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/index.vue?macro=true";
import { default as competencesLHpKFYhg1KMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/competences.vue?macro=true";
import { default as disponibilitesJjTMVrNKsdMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/disponibilites.vue?macro=true";
import { default as preferencesAu5Upz7lsdMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/preferences.vue?macro=true";
import { default as profile9Mmvshdao5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/profile.vue?macro=true";
import { default as indexg79T4j9U5OMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/index.vue?macro=true";
import { default as index9mJxziVjzFMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/index.vue?macro=true";
import { default as charte_45bon_45fonctionnementhwIWgO3MNuMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/charte-bon-fonctionnement.vue?macro=true";
import { default as collectivite_45confirmationjK7fuZE4J6Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/collectivite-confirmation.vue?macro=true";
import { default as collectiviteaLa289S76uMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/collectivite.vue?macro=true";
import { default as organisation_45confirmationZDRgvSEeaFMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-confirmation.vue?macro=true";
import { default as organisation_45detailsJAdj1pCd8fMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-details.vue?macro=true";
import { default as organisation_45imagesAVI7FXBKl7Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-images.vue?macro=true";
import { default as organisationhPkn3APZhuMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation.vue?macro=true";
import { default as profilexwgQv9BWr6Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/profile.vue?macro=true";
import { default as _91hash_93ioPSG8dpSCMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/invitations/[hash].vue?macro=true";
import { default as loginQTvjgtyQ53Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/login.vue?macro=true";
import { default as adminAl9lSAIYtbMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/maintenance/admin.vue?macro=true";
import { default as indexjsMsceNxOzMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/maintenance/index.vue?macro=true";
import { default as mentions_45legalesc7RmnSYhLOMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/mentions-legales.vue?macro=true";
import { default as _91id_93EUWYqJGzfeMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/[id].vue?macro=true";
import { default as indexDpkt39P1pQMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/index.vue?macro=true";
import { default as adduFkeuHyS9hMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/modeles/add.vue?macro=true";
import { default as indexbPnknNY6UiMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/modeles/index.vue?macro=true";
import { default as _91slug_93QpTJcO9ciSMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/[id]/[slug].vue?macro=true";
import { default as indexL4dHMPaTmIMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/[id]/index.vue?macro=true";
import { default as indexkaVoK3dVh5Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/index.vue?macro=true";
import { default as notificationssCyclz5bHOMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/notifications.vue?macro=true";
import { default as _91slug_93xvfqmPoIiLMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/organisations/[slug].vue?macro=true";
import { default as indexlph5U4ZkehMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/organisations/index.vue?macro=true";
import { default as _91token_939bB0jjs627Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/password-reset/[token].vue?macro=true";
import { default as indexH8TZ7pLY9fMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/password-reset/index.vue?macro=true";
import { default as plan_45du_45siteizYzWuJpKeMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/plan-du-site.vue?macro=true";
import { default as politique_45de_45confidentialiteXbjfeaYrclMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/politique-de-confidentialite.vue?macro=true";
import { default as charte_45bon_45fonctionnementRdgtL6FexzMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/charte-bon-fonctionnement.vue?macro=true";
import { default as editVcsCgwm8IaMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/edit.vue?macro=true";
import { default as indexCo4AdVztaeMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/index.vue?macro=true";
import { default as missionskJnE1WIrUIMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/missions.vue?macro=true";
import { default as notifications7v85q6PqU8Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/notifications.vue?macro=true";
import { default as preferencespS8kBEUiP1Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/preferences.vue?macro=true";
import { default as settings8MVm8RD7rDMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/settings.vue?macro=true";
import { default as decembre_45ensemblerhtw5Rn3rnMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/decembre-ensemble.vue?macro=true";
import { default as generiqueZarzrFvWlAMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/generique.vue?macro=true";
import { default as mgenctmubeWwA9Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/mgen.vue?macro=true";
import { default as printemps_45pour_45la_45planeteYnYQXkFGbJMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/printemps-pour-la-planete.vue?macro=true";
import { default as septembre_45pour_45apprendreGvDPXYjL1SMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/septembre-pour-apprendre.vue?macro=true";
import { default as _91slug_93GkgzyWySxIMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/reseaux/[slug].vue?macro=true";
import { default as indexVkdnaifrSxMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/index.vue?macro=true";
import { default as missions2BEpyt20PKMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/missions.vue?macro=true";
import { default as organisationsWHgs4Q0wBYMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/organisations.vue?macro=true";
import { default as participationsiugQkO2OefMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/participations.vue?macro=true";
import { default as places4r55nIrFFjMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/places.vue?macro=true";
import { default as utilisateursAimUqYPcLWMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/utilisateurs.vue?macro=true";
import { default as search_45archived_45usersCganCXgv1UMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/search-archived-users.vue?macro=true";
import { default as transfert_45organisationIri5szbONUMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/transfert-organisation.vue?macro=true";
import { default as user_45reset_45context_45roleXrK9PDQqSmMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/user-reset-context-role.vue?macro=true";
import { default as user_45reset_45password_45linkmU9sTNvbYeMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/user-reset-password-link.vue?macro=true";
import { default as doublons_45organisationsbesKoPP1rmMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/contents/doublons-organisations.vue?macro=true";
import { default as doublons_45territoires6RGHgAxSdoMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/contents/doublons-territoires.vue?macro=true";
import { default as indexYDWkSO1uJoMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/index.vue?macro=true";
import { default as activity_45logsZC4KGaDBcHMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/referents/activity-logs.vue?macro=true";
import { default as waiting_45actionsaDvBlLQUQZMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/referents/waiting-actions.vue?macro=true";
import { default as missions_45outdatedWNS2a1LHLgMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/responsables/missions-outdated.vue?macro=true";
import { default as participations_45to_45be_45treated05eg3yBC2xMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/responsables/participations-to-be-treated.vue?macro=true";
import { default as _91token_9344ttaaWwT6Meta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/temoignages/[token].vue?macro=true";
import { default as indexK9azrK3ofPMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/territoires/index.vue?macro=true";
import { default as _91slug_93HUUsqrUidOMeta } from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/villes/[slug].vue?macro=true";
export default [
  {
    name: accessibilitehcLIuSZqT2Meta?.name ?? "accessibilite",
    path: accessibilitehcLIuSZqT2Meta?.path ?? "/accessibilite",
    meta: accessibilitehcLIuSZqT2Meta || {},
    alias: accessibilitehcLIuSZqT2Meta?.alias || [],
    redirect: accessibilitehcLIuSZqT2Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/accessibilite.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93efCFh0z1GjMeta?.name ?? "activites-slug",
    path: _91slug_93efCFh0z1GjMeta?.path ?? "/activites/:slug()",
    meta: _91slug_93efCFh0z1GjMeta || {},
    alias: _91slug_93efCFh0z1GjMeta?.alias || [],
    redirect: _91slug_93efCFh0z1GjMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/activites/[slug].vue").then(m => m.default || m)
  },
  {
    name: editxOwYwp9NRUMeta?.name ?? "admin-contenus-activites-id-edit",
    path: editxOwYwp9NRUMeta?.path ?? "/admin/contenus/activites/:id()/edit",
    meta: editxOwYwp9NRUMeta || {},
    alias: editxOwYwp9NRUMeta?.alias || [],
    redirect: editxOwYwp9NRUMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexfjnsQ65zppMeta?.name ?? "admin-contenus-activites-id",
    path: indexfjnsQ65zppMeta?.path ?? "/admin/contenus/activites/:id()",
    meta: indexfjnsQ65zppMeta || {},
    alias: indexfjnsQ65zppMeta?.alias || [],
    redirect: indexfjnsQ65zppMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addUjU3K2SKl4Meta?.name ?? "admin-contenus-activites-add",
    path: addUjU3K2SKl4Meta?.path ?? "/admin/contenus/activites/add",
    meta: addUjU3K2SKl4Meta || {},
    alias: addUjU3K2SKl4Meta?.alias || [],
    redirect: addUjU3K2SKl4Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/add.vue").then(m => m.default || m)
  },
  {
    name: index8YxRfisbxLMeta?.name ?? "admin-contenus-activites",
    path: index8YxRfisbxLMeta?.path ?? "/admin/contenus/activites",
    meta: index8YxRfisbxLMeta || {},
    alias: index8YxRfisbxLMeta?.alias || [],
    redirect: index8YxRfisbxLMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/activites/index.vue").then(m => m.default || m)
  },
  {
    name: editt8BspPQ6VCMeta?.name ?? "admin-contenus-domaines-id-edit",
    path: editt8BspPQ6VCMeta?.path ?? "/admin/contenus/domaines/:id()/edit",
    meta: editt8BspPQ6VCMeta || {},
    alias: editt8BspPQ6VCMeta?.alias || [],
    redirect: editt8BspPQ6VCMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: adda9bPSBT3IvMeta?.name ?? "admin-contenus-domaines-add",
    path: adda9bPSBT3IvMeta?.path ?? "/admin/contenus/domaines/add",
    meta: adda9bPSBT3IvMeta || {},
    alias: adda9bPSBT3IvMeta?.alias || [],
    redirect: adda9bPSBT3IvMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/add.vue").then(m => m.default || m)
  },
  {
    name: indexS8tVAs2tyvMeta?.name ?? "admin-contenus-domaines",
    path: indexS8tVAs2tyvMeta?.path ?? "/admin/contenus/domaines",
    meta: indexS8tVAs2tyvMeta || {},
    alias: indexS8tVAs2tyvMeta?.alias || [],
    redirect: indexS8tVAs2tyvMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/domaines/index.vue").then(m => m.default || m)
  },
  {
    name: editkTQSbduXYAMeta?.name ?? "admin-contenus-modeles-mission-id-edit",
    path: editkTQSbduXYAMeta?.path ?? "/admin/contenus/modeles-mission/:id()/edit",
    meta: editkTQSbduXYAMeta || {},
    alias: editkTQSbduXYAMeta?.alias || [],
    redirect: editkTQSbduXYAMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: addwhUEeATXu5Meta?.name ?? "admin-contenus-modeles-mission-add",
    path: addwhUEeATXu5Meta?.path ?? "/admin/contenus/modeles-mission/add",
    meta: addwhUEeATXu5Meta || {},
    alias: addwhUEeATXu5Meta?.alias || [],
    redirect: addwhUEeATXu5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/add.vue").then(m => m.default || m)
  },
  {
    name: indexUfHE9xEhAVMeta?.name ?? "admin-contenus-modeles-mission",
    path: indexUfHE9xEhAVMeta?.path ?? "/admin/contenus/modeles-mission",
    meta: indexUfHE9xEhAVMeta || {},
    alias: indexUfHE9xEhAVMeta?.alias || [],
    redirect: indexUfHE9xEhAVMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/modeles-mission/index.vue").then(m => m.default || m)
  },
  {
    name: editcSxp9mocdtMeta?.name ?? "admin-contenus-reseaux-id-edit",
    path: editcSxp9mocdtMeta?.path ?? "/admin/contenus/reseaux/:id()/edit",
    meta: editcSxp9mocdtMeta || {},
    alias: editcSxp9mocdtMeta?.alias || [],
    redirect: editcSxp9mocdtMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexj8sFnN5UabMeta?.name ?? "admin-contenus-reseaux-id",
    path: indexj8sFnN5UabMeta?.path ?? "/admin/contenus/reseaux/:id()",
    meta: indexj8sFnN5UabMeta || {},
    alias: indexj8sFnN5UabMeta?.alias || [],
    redirect: indexj8sFnN5UabMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addKZtHQtXq5KMeta?.name ?? "admin-contenus-reseaux-add",
    path: addKZtHQtXq5KMeta?.path ?? "/admin/contenus/reseaux/add",
    meta: addKZtHQtXq5KMeta || {},
    alias: addKZtHQtXq5KMeta?.alias || [],
    redirect: addKZtHQtXq5KMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/add.vue").then(m => m.default || m)
  },
  {
    name: index7lFHNYjQ2nMeta?.name ?? "admin-contenus-reseaux",
    path: index7lFHNYjQ2nMeta?.path ?? "/admin/contenus/reseaux",
    meta: index7lFHNYjQ2nMeta || {},
    alias: index7lFHNYjQ2nMeta?.alias || [],
    redirect: index7lFHNYjQ2nMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/reseaux/index.vue").then(m => m.default || m)
  },
  {
    name: editVZA7ALrpngMeta?.name ?? "admin-contenus-ressources-id-edit",
    path: editVZA7ALrpngMeta?.path ?? "/admin/contenus/ressources/:id()/edit",
    meta: editVZA7ALrpngMeta || {},
    alias: editVZA7ALrpngMeta?.alias || [],
    redirect: editVZA7ALrpngMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: addLehpBAFwd5Meta?.name ?? "admin-contenus-ressources-add",
    path: addLehpBAFwd5Meta?.path ?? "/admin/contenus/ressources/add",
    meta: addLehpBAFwd5Meta || {},
    alias: addLehpBAFwd5Meta?.alias || [],
    redirect: addLehpBAFwd5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/add.vue").then(m => m.default || m)
  },
  {
    name: indexClxNKuT3OjMeta?.name ?? "admin-contenus-ressources",
    path: indexClxNKuT3OjMeta?.path ?? "/admin/contenus/ressources",
    meta: indexClxNKuT3OjMeta || {},
    alias: indexClxNKuT3OjMeta?.alias || [],
    redirect: indexClxNKuT3OjMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: editdm7ylvRDiGMeta?.name ?? "admin-contenus-territoires-id-edit",
    path: editdm7ylvRDiGMeta?.path ?? "/admin/contenus/territoires/:id()/edit",
    meta: editdm7ylvRDiGMeta || {},
    alias: editdm7ylvRDiGMeta?.alias || [],
    redirect: editdm7ylvRDiGMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeD4D5Vii6WMeta?.name ?? "admin-contenus-territoires-id",
    path: indexeD4D5Vii6WMeta?.path ?? "/admin/contenus/territoires/:id()",
    meta: indexeD4D5Vii6WMeta || {},
    alias: indexeD4D5Vii6WMeta?.alias || [],
    redirect: indexeD4D5Vii6WMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: add9HHD27fhHuMeta?.name ?? "admin-contenus-territoires-add",
    path: add9HHD27fhHuMeta?.path ?? "/admin/contenus/territoires/add",
    meta: add9HHD27fhHuMeta || {},
    alias: add9HHD27fhHuMeta?.alias || [],
    redirect: add9HHD27fhHuMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/add.vue").then(m => m.default || m)
  },
  {
    name: indextK4ME66pTuMeta?.name ?? "admin-contenus-territoires",
    path: indextK4ME66pTuMeta?.path ?? "/admin/contenus/territoires",
    meta: indextK4ME66pTuMeta || {},
    alias: indextK4ME66pTuMeta?.alias || [],
    redirect: indextK4ME66pTuMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: editDBeYVegX5gMeta?.name ?? "admin-contenus-testimonials-id-edit",
    path: editDBeYVegX5gMeta?.path ?? "/admin/contenus/testimonials/:id()/edit",
    meta: editDBeYVegX5gMeta || {},
    alias: editDBeYVegX5gMeta?.alias || [],
    redirect: editDBeYVegX5gMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/testimonials/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexXJdDqn4578Meta?.name ?? "admin-contenus-testimonials",
    path: indexXJdDqn4578Meta?.path ?? "/admin/contenus/testimonials",
    meta: indexXJdDqn4578Meta || {},
    alias: indexXJdDqn4578Meta?.alias || [],
    redirect: indexXJdDqn4578Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/contenus/testimonials/index.vue").then(m => m.default || m)
  },
  {
    name: indexPw4LVkRxViMeta?.name ?? "admin",
    path: indexPw4LVkRxViMeta?.path ?? "/admin",
    meta: indexPw4LVkRxViMeta || {},
    alias: indexPw4LVkRxViMeta?.alias || [],
    redirect: indexPw4LVkRxViMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: benevoles_45informationseAt3pBeti7Meta?.name ?? "admin-missions-id-benevoles-informations",
    path: benevoles_45informationseAt3pBeti7Meta?.path ?? "/admin/missions/:id()/benevoles-informations",
    meta: benevoles_45informationseAt3pBeti7Meta || {},
    alias: benevoles_45informationseAt3pBeti7Meta?.alias || [],
    redirect: benevoles_45informationseAt3pBeti7Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/benevoles-informations.vue").then(m => m.default || m)
  },
  {
    name: benevoless8bvfVYiCRMeta?.name ?? "admin-missions-id-benevoles",
    path: benevoless8bvfVYiCRMeta?.path ?? "/admin/missions/:id()/benevoles",
    meta: benevoless8bvfVYiCRMeta || {},
    alias: benevoless8bvfVYiCRMeta?.alias || [],
    redirect: benevoless8bvfVYiCRMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/benevoles.vue").then(m => m.default || m)
  },
  {
    name: dateshbEfBLqt6eMeta?.name ?? "admin-missions-id-dates",
    path: dateshbEfBLqt6eMeta?.path ?? "/admin/missions/:id()/dates",
    meta: dateshbEfBLqt6eMeta || {},
    alias: dateshbEfBLqt6eMeta?.alias || [],
    redirect: dateshbEfBLqt6eMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/dates.vue").then(m => m.default || m)
  },
  {
    name: index4HtDZuxH9PMeta?.name ?? "admin-missions-id",
    path: index4HtDZuxH9PMeta?.path ?? "/admin/missions/:id()",
    meta: index4HtDZuxH9PMeta || {},
    alias: index4HtDZuxH9PMeta?.alias || [],
    redirect: index4HtDZuxH9PMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: informationsFvgUTWVKYvMeta?.name ?? "admin-missions-id-informations",
    path: informationsFvgUTWVKYvMeta?.path ?? "/admin/missions/:id()/informations",
    meta: informationsFvgUTWVKYvMeta || {},
    alias: informationsFvgUTWVKYvMeta?.alias || [],
    redirect: informationsFvgUTWVKYvMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/informations.vue").then(m => m.default || m)
  },
  {
    name: lieuxXdHiKDlgRjMeta?.name ?? "admin-missions-id-lieux",
    path: lieuxXdHiKDlgRjMeta?.path ?? "/admin/missions/:id()/lieux",
    meta: lieuxXdHiKDlgRjMeta || {},
    alias: lieuxXdHiKDlgRjMeta?.alias || [],
    redirect: lieuxXdHiKDlgRjMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/lieux.vue").then(m => m.default || m)
  },
  {
    name: responsablesRavWE8X64OMeta?.name ?? "admin-missions-id-responsables",
    path: responsablesRavWE8X64OMeta?.path ?? "/admin/missions/:id()/responsables",
    meta: responsablesRavWE8X64OMeta || {},
    alias: responsablesRavWE8X64OMeta?.alias || [],
    redirect: responsablesRavWE8X64OMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/responsables.vue").then(m => m.default || m)
  },
  {
    name: title6Lfdcb1uQXMeta?.name ?? "admin-missions-id-title",
    path: title6Lfdcb1uQXMeta?.path ?? "/admin/missions/:id()/title",
    meta: title6Lfdcb1uQXMeta || {},
    alias: title6Lfdcb1uQXMeta?.alias || [],
    redirect: title6Lfdcb1uQXMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/title.vue").then(m => m.default || m)
  },
  {
    name: trouver_45des_45benevoleskihO3AppwJMeta?.name ?? "admin-missions-id-trouver-des-benevoles",
    path: trouver_45des_45benevoleskihO3AppwJMeta?.path ?? "/admin/missions/:id()/trouver-des-benevoles",
    meta: trouver_45des_45benevoleskihO3AppwJMeta || {},
    alias: trouver_45des_45benevoleskihO3AppwJMeta?.alias || [],
    redirect: trouver_45des_45benevoleskihO3AppwJMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/trouver-des-benevoles.vue").then(m => m.default || m)
  },
  {
    name: visuel6UKVcn4jVLMeta?.name ?? "admin-missions-id-visuel",
    path: visuel6UKVcn4jVLMeta?.path ?? "/admin/missions/:id()/visuel",
    meta: visuel6UKVcn4jVLMeta || {},
    alias: visuel6UKVcn4jVLMeta?.alias || [],
    redirect: visuel6UKVcn4jVLMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/[id]/visuel.vue").then(m => m.default || m)
  },
  {
    name: indexmbDBkMp6V4Meta?.name ?? "admin-missions",
    path: indexmbDBkMp6V4Meta?.path ?? "/admin/missions",
    meta: indexmbDBkMp6V4Meta || {},
    alias: indexmbDBkMp6V4Meta?.alias || [],
    redirect: indexmbDBkMp6V4Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/missions/index.vue").then(m => m.default || m)
  },
  {
    name: notesH3FRxWzAM0Meta?.name ?? "admin-notes",
    path: notesH3FRxWzAM0Meta?.path ?? "/admin/notes",
    meta: notesH3FRxWzAM0Meta || {},
    alias: notesH3FRxWzAM0Meta?.alias || [],
    redirect: notesH3FRxWzAM0Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/notes.vue").then(m => m.default || m)
  },
  {
    name: editWZ3vzlFidXMeta?.name ?? "admin-organisations-id-edit",
    path: editWZ3vzlFidXMeta?.path ?? "/admin/organisations/:id()/edit",
    meta: editWZ3vzlFidXMeta || {},
    alias: editWZ3vzlFidXMeta?.alias || [],
    redirect: editWZ3vzlFidXMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: index2yQvvE5id4Meta?.name ?? "admin-organisations-id",
    path: index2yQvvE5id4Meta?.path ?? "/admin/organisations/:id()",
    meta: index2yQvvE5id4Meta || {},
    alias: index2yQvvE5id4Meta?.alias || [],
    redirect: index2yQvvE5id4Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addo2WijHjgZqMeta?.name ?? "admin-organisations-id-missions-add",
    path: addo2WijHjgZqMeta?.path ?? "/admin/organisations/:id()/missions/add",
    meta: addo2WijHjgZqMeta || {},
    alias: addo2WijHjgZqMeta?.alias || [],
    redirect: addo2WijHjgZqMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/[id]/missions/add.vue").then(m => m.default || m)
  },
  {
    name: indexAO5LT1MvRrMeta?.name ?? "admin-organisations",
    path: indexAO5LT1MvRrMeta?.path ?? "/admin/organisations",
    meta: indexAO5LT1MvRrMeta || {},
    alias: indexAO5LT1MvRrMeta?.alias || [],
    redirect: indexAO5LT1MvRrMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: invitationse73IXtq2gMMeta?.name ?? "admin-other-invitations",
    path: invitationse73IXtq2gMMeta?.path ?? "/admin/other/invitations",
    meta: invitationse73IXtq2gMMeta || {},
    alias: invitationse73IXtq2gMMeta?.alias || [],
    redirect: invitationse73IXtq2gMMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/other/invitations.vue").then(m => m.default || m)
  },
  {
    name: logsQS9YO177ovMeta?.name ?? "admin-other-logs",
    path: logsQS9YO177ovMeta?.path ?? "/admin/other/logs",
    meta: logsQS9YO177ovMeta || {},
    alias: logsQS9YO177ovMeta?.alias || [],
    redirect: logsQS9YO177ovMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/other/logs.vue").then(m => m.default || m)
  },
  {
    name: indexfCTjz88VK5Meta?.name ?? "admin-participations",
    path: indexfCTjz88VK5Meta?.path ?? "/admin/participations",
    meta: indexfCTjz88VK5Meta || {},
    alias: indexfCTjz88VK5Meta?.alias || [],
    redirect: indexfCTjz88VK5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/participations/index.vue").then(m => m.default || m)
  },
  {
    name: ressourcesvUWMI4YH9EMeta?.name ?? "admin-ressources",
    path: ressourcesvUWMI4YH9EMeta?.path ?? "/admin/ressources",
    meta: ressourcesvUWMI4YH9EMeta || {},
    alias: ressourcesvUWMI4YH9EMeta?.alias || [],
    redirect: ressourcesvUWMI4YH9EMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/ressources.vue").then(m => m.default || m)
  },
  {
    name: emailscWVpbAGHGjMeta?.name ?? "admin-settings-emails",
    path: emailscWVpbAGHGjMeta?.path ?? "/admin/settings/emails",
    meta: emailscWVpbAGHGjMeta || {},
    alias: emailscWVpbAGHGjMeta?.alias || [],
    redirect: emailscWVpbAGHGjMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/emails.vue").then(m => m.default || m)
  },
  {
    name: generalAtcCTWLeqgMeta?.name ?? "admin-settings-general",
    path: generalAtcCTWLeqgMeta?.path ?? "/admin/settings/general",
    meta: generalAtcCTWLeqgMeta || {},
    alias: generalAtcCTWLeqgMeta?.alias || [],
    redirect: generalAtcCTWLeqgMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: messagesqQVdHWuthrMeta?.name ?? "admin-settings-messages",
    path: messagesqQVdHWuthrMeta?.path ?? "/admin/settings/messages",
    meta: messagesqQVdHWuthrMeta || {},
    alias: messagesqQVdHWuthrMeta?.alias || [],
    redirect: messagesqQVdHWuthrMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/messages.vue").then(m => m.default || m)
  },
  {
    name: editdiPKMqfDZ8Meta?.name ?? "admin-settings-rules-id-edit",
    path: editdiPKMqfDZ8Meta?.path ?? "/admin/settings/rules/:id()/edit",
    meta: editdiPKMqfDZ8Meta || {},
    alias: editdiPKMqfDZ8Meta?.alias || [],
    redirect: editdiPKMqfDZ8Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: pending_45items98DBXMb0LUMeta?.name ?? "admin-settings-rules-id-pending-items",
    path: pending_45items98DBXMb0LUMeta?.path ?? "/admin/settings/rules/:id()/pending-items",
    meta: pending_45items98DBXMb0LUMeta || {},
    alias: pending_45items98DBXMb0LUMeta?.alias || [],
    redirect: pending_45items98DBXMb0LUMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/[id]/pending-items.vue").then(m => m.default || m)
  },
  {
    name: addTk5Sits13dMeta?.name ?? "admin-settings-rules-add",
    path: addTk5Sits13dMeta?.path ?? "/admin/settings/rules/add",
    meta: addTk5Sits13dMeta || {},
    alias: addTk5Sits13dMeta?.alias || [],
    redirect: addTk5Sits13dMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/add.vue").then(m => m.default || m)
  },
  {
    name: indexiI9WegPmTyMeta?.name ?? "admin-settings-rules",
    path: indexiI9WegPmTyMeta?.path ?? "/admin/settings/rules",
    meta: indexiI9WegPmTyMeta || {},
    alias: indexiI9WegPmTyMeta?.alias || [],
    redirect: indexiI9WegPmTyMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/settings/rules/index.vue").then(m => m.default || m)
  },
  {
    name: trafic_45entrantUufkcfHja5Meta?.name ?? "admin-statistics-api-engagement-trafic-entrant",
    path: trafic_45entrantUufkcfHja5Meta?.path ?? "/admin/statistics/api-engagement/trafic-entrant",
    meta: trafic_45entrantUufkcfHja5Meta || {},
    alias: trafic_45entrantUufkcfHja5Meta?.alias || [],
    redirect: trafic_45entrantUufkcfHja5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/api-engagement/trafic-entrant.vue").then(m => m.default || m)
  },
  {
    name: trafic_45sortantek7GJgvYFJMeta?.name ?? "admin-statistics-api-engagement-trafic-sortant",
    path: trafic_45sortantek7GJgvYFJMeta?.path ?? "/admin/statistics/api-engagement/trafic-sortant",
    meta: trafic_45sortantek7GJgvYFJMeta || {},
    alias: trafic_45sortantek7GJgvYFJMeta?.alias || [],
    redirect: trafic_45sortantek7GJgvYFJMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/api-engagement/trafic-sortant.vue").then(m => m.default || m)
  },
  {
    name: conversionskaDxwiUk2DMeta?.name ?? "admin-statistics-conversions",
    path: conversionskaDxwiUk2DMeta?.path ?? "/admin/statistics/conversions",
    meta: conversionskaDxwiUk2DMeta || {},
    alias: conversionskaDxwiUk2DMeta?.alias || [],
    redirect: conversionskaDxwiUk2DMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/conversions.vue").then(m => m.default || m)
  },
  {
    name: missions_45a_45validerEMM74da7MDMeta?.name ?? "admin-statistics-departements-missions-a-valider",
    path: missions_45a_45validerEMM74da7MDMeta?.path ?? "/admin/statistics/departements/missions-a-valider",
    meta: missions_45a_45validerEMM74da7MDMeta || {},
    alias: missions_45a_45validerEMM74da7MDMeta?.alias || [],
    redirect: missions_45a_45validerEMM74da7MDMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/missions-a-valider.vue").then(m => m.default || m)
  },
  {
    name: missions_45perimees0AIHMvcokdMeta?.name ?? "admin-statistics-departements-missions-perimees",
    path: missions_45perimees0AIHMvcokdMeta?.path ?? "/admin/statistics/departements/missions-perimees",
    meta: missions_45perimees0AIHMvcokdMeta || {},
    alias: missions_45perimees0AIHMvcokdMeta?.alias || [],
    redirect: missions_45perimees0AIHMvcokdMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: organisations_45a_45validerBnJhhJGCL6Meta?.name ?? "admin-statistics-departements-organisations-a-valider",
    path: organisations_45a_45validerBnJhhJGCL6Meta?.path ?? "/admin/statistics/departements/organisations-a-valider",
    meta: organisations_45a_45validerBnJhhJGCL6Meta || {},
    alias: organisations_45a_45validerBnJhhJGCL6Meta?.alias || [],
    redirect: organisations_45a_45validerBnJhhJGCL6Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/departements/organisations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: indexw1lPop069dMeta?.name ?? "admin-statistics",
    path: indexw1lPop069dMeta?.path ?? "/admin/statistics",
    meta: indexw1lPop069dMeta || {},
    alias: indexw1lPop069dMeta?.alias || [],
    redirect: indexw1lPop069dMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: indicateurs_45clesqp8lV5I7VMMeta?.name ?? "admin-statistics-indicateurs-cles",
    path: indicateurs_45clesqp8lV5I7VMMeta?.path ?? "/admin/statistics/indicateurs-cles",
    meta: indicateurs_45clesqp8lV5I7VMMeta || {},
    alias: indicateurs_45clesqp8lV5I7VMMeta?.alias || [],
    redirect: indicateurs_45clesqp8lV5I7VMMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/indicateurs-cles.vue").then(m => m.default || m)
  },
  {
    name: missions1CfmgvWK9YMeta?.name ?? "admin-statistics-missions",
    path: missions1CfmgvWK9YMeta?.path ?? "/admin/statistics/missions",
    meta: missions1CfmgvWK9YMeta || {},
    alias: missions1CfmgvWK9YMeta?.alias || [],
    redirect: missions1CfmgvWK9YMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/missions.vue").then(m => m.default || m)
  },
  {
    name: index1MsJAEItGvMeta?.name ?? "admin-statistics-organisations",
    path: index1MsJAEItGvMeta?.path ?? "/admin/statistics/organisations",
    meta: index1MsJAEItGvMeta || {},
    alias: index1MsJAEItGvMeta?.alias || [],
    redirect: index1MsJAEItGvMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: missions_45perimeesbtXLYLN6eXMeta?.name ?? "admin-statistics-organisations-missions-perimees",
    path: missions_45perimeesbtXLYLN6eXMeta?.path ?? "/admin/statistics/organisations/missions-perimees",
    meta: missions_45perimeesbtXLYLN6eXMeta || {},
    alias: missions_45perimeesbtXLYLN6eXMeta?.alias || [],
    redirect: missions_45perimeesbtXLYLN6eXMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/missions-perimees.vue").then(m => m.default || m)
  },
  {
    name: participations_45a_45valider7CiQ6u28ioMeta?.name ?? "admin-statistics-organisations-participations-a-valider",
    path: participations_45a_45valider7CiQ6u28ioMeta?.path ?? "/admin/statistics/organisations/participations-a-valider",
    meta: participations_45a_45valider7CiQ6u28ioMeta || {},
    alias: participations_45a_45valider7CiQ6u28ioMeta?.alias || [],
    redirect: participations_45a_45valider7CiQ6u28ioMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/participations-a-valider.vue").then(m => m.default || m)
  },
  {
    name: participations_45refusees_45annuleesVMV1MBqXgQMeta?.name ?? "admin-statistics-organisations-participations-refusees-annulees",
    path: participations_45refusees_45annuleesVMV1MBqXgQMeta?.path ?? "/admin/statistics/organisations/participations-refusees-annulees",
    meta: participations_45refusees_45annuleesVMV1MBqXgQMeta || {},
    alias: participations_45refusees_45annuleesVMV1MBqXgQMeta?.alias || [],
    redirect: participations_45refusees_45annuleesVMV1MBqXgQMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/organisations/participations-refusees-annulees.vue").then(m => m.default || m)
  },
  {
    name: participationsZW5MVEtCO6Meta?.name ?? "admin-statistics-participations",
    path: participationsZW5MVEtCO6Meta?.path ?? "/admin/statistics/participations",
    meta: participationsZW5MVEtCO6Meta || {},
    alias: participationsZW5MVEtCO6Meta?.alias || [],
    redirect: participationsZW5MVEtCO6Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/participations.vue").then(m => m.default || m)
  },
  {
    name: placesdfvC2ceUVIMeta?.name ?? "admin-statistics-places",
    path: placesdfvC2ceUVIMeta?.path ?? "/admin/statistics/places",
    meta: placesdfvC2ceUVIMeta || {},
    alias: placesdfvC2ceUVIMeta?.alias || [],
    redirect: placesdfvC2ceUVIMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/places.vue").then(m => m.default || m)
  },
  {
    name: utilisateurspgTTQsKFVVMeta?.name ?? "admin-statistics-utilisateurs",
    path: utilisateurspgTTQsKFVVMeta?.path ?? "/admin/statistics/utilisateurs",
    meta: utilisateurspgTTQsKFVVMeta || {},
    alias: utilisateurspgTTQsKFVVMeta?.alias || [],
    redirect: utilisateurspgTTQsKFVVMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/statistics/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: edit9r5yyIikK9Meta?.name ?? "admin-taxonomies-slug-id-edit",
    path: edit9r5yyIikK9Meta?.path ?? "/admin/taxonomies/:slug()/:id()/edit",
    meta: edit9r5yyIikK9Meta || {},
    alias: edit9r5yyIikK9Meta?.alias || [],
    redirect: edit9r5yyIikK9Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: adddZU8G2H7NeMeta?.name ?? "admin-taxonomies-slug-add",
    path: adddZU8G2H7NeMeta?.path ?? "/admin/taxonomies/:slug()/add",
    meta: adddZU8G2H7NeMeta || {},
    alias: adddZU8G2H7NeMeta?.alias || [],
    redirect: adddZU8G2H7NeMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/add.vue").then(m => m.default || m)
  },
  {
    name: indexYhI5cH6f79Meta?.name ?? "admin-taxonomies-slug",
    path: indexYhI5cH6f79Meta?.path ?? "/admin/taxonomies/:slug()",
    meta: indexYhI5cH6f79Meta || {},
    alias: indexYhI5cH6f79Meta?.alias || [],
    redirect: indexYhI5cH6f79Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/taxonomies/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: temoignagesCkeUsDDlAlMeta?.name ?? "admin-temoignages",
    path: temoignagesCkeUsDDlAlMeta?.path ?? "/admin/temoignages",
    meta: temoignagesCkeUsDDlAlMeta || {},
    alias: temoignagesCkeUsDDlAlMeta?.alias || [],
    redirect: temoignagesCkeUsDDlAlMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/temoignages.vue").then(m => m.default || m)
  },
  {
    name: editfJ9w5mlQ7hMeta?.name ?? "admin-utilisateurs-id-edit",
    path: editfJ9w5mlQ7hMeta?.path ?? "/admin/utilisateurs/:id()/edit",
    meta: editfJ9w5mlQ7hMeta || {},
    alias: editfJ9w5mlQ7hMeta?.alias || [],
    redirect: editfJ9w5mlQ7hMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeDsX6CtI8ZMeta?.name ?? "admin-utilisateurs-id",
    path: indexeDsX6CtI8ZMeta?.path ?? "/admin/utilisateurs/:id()",
    meta: indexeDsX6CtI8ZMeta || {},
    alias: indexeDsX6CtI8ZMeta?.alias || [],
    redirect: indexeDsX6CtI8ZMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexecUEHsRlHOMeta?.name ?? "admin-utilisateurs",
    path: indexecUEHsRlHOMeta?.path ?? "/admin/utilisateurs",
    meta: indexecUEHsRlHOMeta || {},
    alias: indexecUEHsRlHOMeta?.alias || [],
    redirect: indexecUEHsRlHOMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/admin/utilisateurs/index.vue").then(m => m.default || m)
  },
  {
    name: benevolesNy0vh6HAEJMeta?.name ?? "charte-bon-fonctionnement-benevoles",
    path: benevolesNy0vh6HAEJMeta?.path ?? "/charte-bon-fonctionnement/benevoles",
    meta: benevolesNy0vh6HAEJMeta || {},
    alias: benevolesNy0vh6HAEJMeta?.alias || [],
    redirect: benevolesNy0vh6HAEJMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/benevoles.vue").then(m => m.default || m)
  },
  {
    name: indexlCS2lsN7v3Meta?.name ?? "charte-bon-fonctionnement",
    path: indexlCS2lsN7v3Meta?.path ?? "/charte-bon-fonctionnement",
    meta: indexlCS2lsN7v3Meta || {},
    alias: indexlCS2lsN7v3Meta?.alias || [],
    redirect: indexlCS2lsN7v3Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/index.vue").then(m => m.default || m)
  },
  {
    name: responsablesQdUggZ5g0FMeta?.name ?? "charte-bon-fonctionnement-responsables",
    path: responsablesQdUggZ5g0FMeta?.path ?? "/charte-bon-fonctionnement/responsables",
    meta: responsablesQdUggZ5g0FMeta || {},
    alias: responsablesQdUggZ5g0FMeta?.alias || [],
    redirect: responsablesQdUggZ5g0FMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-bon-fonctionnement/responsables.vue").then(m => m.default || m)
  },
  {
    name: charte_45reserve_45civiqueVIMzIfzR9sMeta?.name ?? "charte-reserve-civique",
    path: charte_45reserve_45civiqueVIMzIfzR9sMeta?.path ?? "/charte-reserve-civique",
    meta: charte_45reserve_45civiqueVIMzIfzR9sMeta || {},
    alias: charte_45reserve_45civiqueVIMzIfzR9sMeta?.alias || [],
    redirect: charte_45reserve_45civiqueVIMzIfzR9sMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/charte-reserve-civique.vue").then(m => m.default || m)
  },
  {
    name: conditions_45generales_45d_45utilisation6Xcnk93h3cMeta?.name ?? "conditions-generales-d-utilisation",
    path: conditions_45generales_45d_45utilisation6Xcnk93h3cMeta?.path ?? "/conditions-generales-d-utilisation",
    meta: conditions_45generales_45d_45utilisation6Xcnk93h3cMeta || {},
    alias: conditions_45generales_45d_45utilisation6Xcnk93h3cMeta?.alias || [],
    redirect: conditions_45generales_45d_45utilisation6Xcnk93h3cMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/conditions-generales-d-utilisation.vue").then(m => m.default || m)
  },
  {
    name: indexySwUgkt2cEMeta?.name ?? "dashboard",
    path: indexySwUgkt2cEMeta?.path ?? "/dashboard",
    meta: indexySwUgkt2cEMeta || {},
    alias: indexySwUgkt2cEMeta?.alias || [],
    redirect: indexySwUgkt2cEMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DkHUssqcAxMeta?.name ?? "departements-slug",
    path: _91slug_93DkHUssqcAxMeta?.path ?? "/departements/:slug()",
    meta: _91slug_93DkHUssqcAxMeta || {},
    alias: _91slug_93DkHUssqcAxMeta?.alias || [],
    redirect: _91slug_93DkHUssqcAxMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/departements/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SFTF9Lbg8LMeta?.name ?? "domaines-action-slug",
    path: _91slug_93SFTF9Lbg8LMeta?.path ?? "/domaines-action/:slug()",
    meta: _91slug_93SFTF9Lbg8LMeta || {},
    alias: _91slug_93SFTF9Lbg8LMeta?.alias || [],
    redirect: _91slug_93SFTF9Lbg8LMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/domaines-action/[slug].vue").then(m => m.default || m)
  },
  {
    name: en_45ce_45momentfmss0rgCsAMeta?.name ?? "en-ce-moment",
    path: en_45ce_45momentfmss0rgCsAMeta?.path ?? "/en-ce-moment",
    meta: en_45ce_45momentfmss0rgCsAMeta || {},
    alias: en_45ce_45momentfmss0rgCsAMeta?.alias || [],
    redirect: en_45ce_45momentfmss0rgCsAMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/en-ce-moment.vue").then(m => m.default || m)
  },
  {
    name: indexKo6eviulkbMeta?.name ?? "index",
    path: indexKo6eviulkbMeta?.path ?? "/",
    meta: indexKo6eviulkbMeta || {},
    alias: indexKo6eviulkbMeta?.alias || [],
    redirect: indexKo6eviulkbMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwbwf3pPxzCMeta?.name ?? "inscription-benevole",
    path: indexwbwf3pPxzCMeta?.path ?? "/inscription/benevole",
    meta: indexwbwf3pPxzCMeta || {},
    alias: indexwbwf3pPxzCMeta?.alias || [],
    redirect: indexwbwf3pPxzCMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/index.vue").then(m => m.default || m)
  },
  {
    name: competencesLHpKFYhg1KMeta?.name ?? "inscription-benevole-step-competences",
    path: competencesLHpKFYhg1KMeta?.path ?? "/inscription/benevole/step/competences",
    meta: competencesLHpKFYhg1KMeta || {},
    alias: competencesLHpKFYhg1KMeta?.alias || [],
    redirect: competencesLHpKFYhg1KMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/competences.vue").then(m => m.default || m)
  },
  {
    name: disponibilitesJjTMVrNKsdMeta?.name ?? "inscription-benevole-step-disponibilites",
    path: disponibilitesJjTMVrNKsdMeta?.path ?? "/inscription/benevole/step/disponibilites",
    meta: disponibilitesJjTMVrNKsdMeta || {},
    alias: disponibilitesJjTMVrNKsdMeta?.alias || [],
    redirect: disponibilitesJjTMVrNKsdMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/disponibilites.vue").then(m => m.default || m)
  },
  {
    name: preferencesAu5Upz7lsdMeta?.name ?? "inscription-benevole-step-preferences",
    path: preferencesAu5Upz7lsdMeta?.path ?? "/inscription/benevole/step/preferences",
    meta: preferencesAu5Upz7lsdMeta || {},
    alias: preferencesAu5Upz7lsdMeta?.alias || [],
    redirect: preferencesAu5Upz7lsdMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/preferences.vue").then(m => m.default || m)
  },
  {
    name: profile9Mmvshdao5Meta?.name ?? "inscription-benevole-step-profile",
    path: profile9Mmvshdao5Meta?.path ?? "/inscription/benevole/step/profile",
    meta: profile9Mmvshdao5Meta || {},
    alias: profile9Mmvshdao5Meta?.alias || [],
    redirect: profile9Mmvshdao5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/benevole/step/profile.vue").then(m => m.default || m)
  },
  {
    name: indexg79T4j9U5OMeta?.name ?? "inscription",
    path: indexg79T4j9U5OMeta?.path ?? "/inscription",
    meta: indexg79T4j9U5OMeta || {},
    alias: indexg79T4j9U5OMeta?.alias || [],
    redirect: indexg79T4j9U5OMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/index.vue").then(m => m.default || m)
  },
  {
    name: index9mJxziVjzFMeta?.name ?? "inscription-responsable",
    path: index9mJxziVjzFMeta?.path ?? "/inscription/responsable",
    meta: index9mJxziVjzFMeta || {},
    alias: index9mJxziVjzFMeta?.alias || [],
    redirect: index9mJxziVjzFMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/index.vue").then(m => m.default || m)
  },
  {
    name: charte_45bon_45fonctionnementhwIWgO3MNuMeta?.name ?? "inscription-responsable-step-charte-bon-fonctionnement",
    path: charte_45bon_45fonctionnementhwIWgO3MNuMeta?.path ?? "/inscription/responsable/step/charte-bon-fonctionnement",
    meta: charte_45bon_45fonctionnementhwIWgO3MNuMeta || {},
    alias: charte_45bon_45fonctionnementhwIWgO3MNuMeta?.alias || [],
    redirect: charte_45bon_45fonctionnementhwIWgO3MNuMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: collectivite_45confirmationjK7fuZE4J6Meta?.name ?? "inscription-responsable-step-collectivite-confirmation",
    path: collectivite_45confirmationjK7fuZE4J6Meta?.path ?? "/inscription/responsable/step/collectivite-confirmation",
    meta: collectivite_45confirmationjK7fuZE4J6Meta || {},
    alias: collectivite_45confirmationjK7fuZE4J6Meta?.alias || [],
    redirect: collectivite_45confirmationjK7fuZE4J6Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/collectivite-confirmation.vue").then(m => m.default || m)
  },
  {
    name: collectiviteaLa289S76uMeta?.name ?? "inscription-responsable-step-collectivite",
    path: collectiviteaLa289S76uMeta?.path ?? "/inscription/responsable/step/collectivite",
    meta: collectiviteaLa289S76uMeta || {},
    alias: collectiviteaLa289S76uMeta?.alias || [],
    redirect: collectiviteaLa289S76uMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/collectivite.vue").then(m => m.default || m)
  },
  {
    name: organisation_45confirmationZDRgvSEeaFMeta?.name ?? "inscription-responsable-step-organisation-confirmation",
    path: organisation_45confirmationZDRgvSEeaFMeta?.path ?? "/inscription/responsable/step/organisation-confirmation",
    meta: organisation_45confirmationZDRgvSEeaFMeta || {},
    alias: organisation_45confirmationZDRgvSEeaFMeta?.alias || [],
    redirect: organisation_45confirmationZDRgvSEeaFMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-confirmation.vue").then(m => m.default || m)
  },
  {
    name: organisation_45detailsJAdj1pCd8fMeta?.name ?? "inscription-responsable-step-organisation-details",
    path: organisation_45detailsJAdj1pCd8fMeta?.path ?? "/inscription/responsable/step/organisation-details",
    meta: organisation_45detailsJAdj1pCd8fMeta || {},
    alias: organisation_45detailsJAdj1pCd8fMeta?.alias || [],
    redirect: organisation_45detailsJAdj1pCd8fMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-details.vue").then(m => m.default || m)
  },
  {
    name: organisation_45imagesAVI7FXBKl7Meta?.name ?? "inscription-responsable-step-organisation-images",
    path: organisation_45imagesAVI7FXBKl7Meta?.path ?? "/inscription/responsable/step/organisation-images",
    meta: organisation_45imagesAVI7FXBKl7Meta || {},
    alias: organisation_45imagesAVI7FXBKl7Meta?.alias || [],
    redirect: organisation_45imagesAVI7FXBKl7Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation-images.vue").then(m => m.default || m)
  },
  {
    name: organisationhPkn3APZhuMeta?.name ?? "inscription-responsable-step-organisation",
    path: organisationhPkn3APZhuMeta?.path ?? "/inscription/responsable/step/organisation",
    meta: organisationhPkn3APZhuMeta || {},
    alias: organisationhPkn3APZhuMeta?.alias || [],
    redirect: organisationhPkn3APZhuMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/organisation.vue").then(m => m.default || m)
  },
  {
    name: profilexwgQv9BWr6Meta?.name ?? "inscription-responsable-step-profile",
    path: profilexwgQv9BWr6Meta?.path ?? "/inscription/responsable/step/profile",
    meta: profilexwgQv9BWr6Meta || {},
    alias: profilexwgQv9BWr6Meta?.alias || [],
    redirect: profilexwgQv9BWr6Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/inscription/responsable/step/profile.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93ioPSG8dpSCMeta?.name ?? "invitations-hash",
    path: _91hash_93ioPSG8dpSCMeta?.path ?? "/invitations/:hash()",
    meta: _91hash_93ioPSG8dpSCMeta || {},
    alias: _91hash_93ioPSG8dpSCMeta?.alias || [],
    redirect: _91hash_93ioPSG8dpSCMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/invitations/[hash].vue").then(m => m.default || m)
  },
  {
    name: loginQTvjgtyQ53Meta?.name ?? "login",
    path: loginQTvjgtyQ53Meta?.path ?? "/login",
    meta: loginQTvjgtyQ53Meta || {},
    alias: loginQTvjgtyQ53Meta?.alias || [],
    redirect: loginQTvjgtyQ53Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/login.vue").then(m => m.default || m)
  },
  {
    name: adminAl9lSAIYtbMeta?.name ?? "maintenance-admin",
    path: adminAl9lSAIYtbMeta?.path ?? "/maintenance/admin",
    meta: adminAl9lSAIYtbMeta || {},
    alias: adminAl9lSAIYtbMeta?.alias || [],
    redirect: adminAl9lSAIYtbMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/maintenance/admin.vue").then(m => m.default || m)
  },
  {
    name: indexjsMsceNxOzMeta?.name ?? "maintenance",
    path: indexjsMsceNxOzMeta?.path ?? "/maintenance",
    meta: indexjsMsceNxOzMeta || {},
    alias: indexjsMsceNxOzMeta?.alias || [],
    redirect: indexjsMsceNxOzMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesc7RmnSYhLOMeta?.name ?? "mentions-legales",
    path: mentions_45legalesc7RmnSYhLOMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesc7RmnSYhLOMeta || {},
    alias: mentions_45legalesc7RmnSYhLOMeta?.alias || [],
    redirect: mentions_45legalesc7RmnSYhLOMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: _91id_93EUWYqJGzfeMeta?.name ?? "messages-id",
    path: _91id_93EUWYqJGzfeMeta?.path ?? "/messages/:id()",
    meta: _91id_93EUWYqJGzfeMeta || {},
    alias: _91id_93EUWYqJGzfeMeta?.alias || [],
    redirect: _91id_93EUWYqJGzfeMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDpkt39P1pQMeta?.name ?? "messages",
    path: indexDpkt39P1pQMeta?.path ?? "/messages",
    meta: indexDpkt39P1pQMeta || {},
    alias: indexDpkt39P1pQMeta?.alias || [],
    redirect: indexDpkt39P1pQMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: adduFkeuHyS9hMeta?.name ?? "messages-modeles-add",
    path: adduFkeuHyS9hMeta?.path ?? "/messages/modeles/add",
    meta: adduFkeuHyS9hMeta || {},
    alias: adduFkeuHyS9hMeta?.alias || [],
    redirect: adduFkeuHyS9hMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/modeles/add.vue").then(m => m.default || m)
  },
  {
    name: indexbPnknNY6UiMeta?.name ?? "messages-modeles",
    path: indexbPnknNY6UiMeta?.path ?? "/messages/modeles",
    meta: indexbPnknNY6UiMeta || {},
    alias: indexbPnknNY6UiMeta?.alias || [],
    redirect: indexbPnknNY6UiMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/messages/modeles/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93QpTJcO9ciSMeta?.name ?? "missions-benevolat-id-slug",
    path: _91slug_93QpTJcO9ciSMeta?.path ?? "/missions-benevolat/:id()/:slug()",
    meta: _91slug_93QpTJcO9ciSMeta || {},
    alias: _91slug_93QpTJcO9ciSMeta?.alias || [],
    redirect: _91slug_93QpTJcO9ciSMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/[id]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexL4dHMPaTmIMeta?.name ?? "missions-benevolat-id",
    path: indexL4dHMPaTmIMeta?.path ?? "/missions-benevolat/:id()",
    meta: indexL4dHMPaTmIMeta || {},
    alias: indexL4dHMPaTmIMeta?.alias || [],
    redirect: indexL4dHMPaTmIMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkaVoK3dVh5Meta?.name ?? "missions-benevolat",
    path: indexkaVoK3dVh5Meta?.path ?? "/missions-benevolat",
    meta: indexkaVoK3dVh5Meta || {},
    alias: indexkaVoK3dVh5Meta?.alias || [],
    redirect: indexkaVoK3dVh5Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/missions-benevolat/index.vue").then(m => m.default || m)
  },
  {
    name: notificationssCyclz5bHOMeta?.name ?? "notifications",
    path: notificationssCyclz5bHOMeta?.path ?? "/notifications",
    meta: notificationssCyclz5bHOMeta || {},
    alias: notificationssCyclz5bHOMeta?.alias || [],
    redirect: notificationssCyclz5bHOMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93xvfqmPoIiLMeta?.name ?? "organisations-slug",
    path: _91slug_93xvfqmPoIiLMeta?.path ?? "/organisations/:slug()",
    meta: _91slug_93xvfqmPoIiLMeta || {},
    alias: _91slug_93xvfqmPoIiLMeta?.alias || [],
    redirect: _91slug_93xvfqmPoIiLMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/organisations/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexlph5U4ZkehMeta?.name ?? "organisations",
    path: indexlph5U4ZkehMeta?.path ?? "/organisations",
    meta: indexlph5U4ZkehMeta || {},
    alias: indexlph5U4ZkehMeta?.alias || [],
    redirect: indexlph5U4ZkehMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_939bB0jjs627Meta?.name ?? "password-reset-token",
    path: _91token_939bB0jjs627Meta?.path ?? "/password-reset/:token()",
    meta: _91token_939bB0jjs627Meta || {},
    alias: _91token_939bB0jjs627Meta?.alias || [],
    redirect: _91token_939bB0jjs627Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexH8TZ7pLY9fMeta?.name ?? "password-reset",
    path: indexH8TZ7pLY9fMeta?.path ?? "/password-reset",
    meta: indexH8TZ7pLY9fMeta || {},
    alias: indexH8TZ7pLY9fMeta?.alias || [],
    redirect: indexH8TZ7pLY9fMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: plan_45du_45siteizYzWuJpKeMeta?.name ?? "plan-du-site",
    path: plan_45du_45siteizYzWuJpKeMeta?.path ?? "/plan-du-site",
    meta: plan_45du_45siteizYzWuJpKeMeta || {},
    alias: plan_45du_45siteizYzWuJpKeMeta?.alias || [],
    redirect: plan_45du_45siteizYzWuJpKeMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/plan-du-site.vue").then(m => m.default || m)
  },
  {
    name: politique_45de_45confidentialiteXbjfeaYrclMeta?.name ?? "politique-de-confidentialite",
    path: politique_45de_45confidentialiteXbjfeaYrclMeta?.path ?? "/politique-de-confidentialite",
    meta: politique_45de_45confidentialiteXbjfeaYrclMeta || {},
    alias: politique_45de_45confidentialiteXbjfeaYrclMeta?.alias || [],
    redirect: politique_45de_45confidentialiteXbjfeaYrclMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/politique-de-confidentialite.vue").then(m => m.default || m)
  },
  {
    name: charte_45bon_45fonctionnementRdgtL6FexzMeta?.name ?? "profile-charte-bon-fonctionnement",
    path: charte_45bon_45fonctionnementRdgtL6FexzMeta?.path ?? "/profile/charte-bon-fonctionnement",
    meta: charte_45bon_45fonctionnementRdgtL6FexzMeta || {},
    alias: charte_45bon_45fonctionnementRdgtL6FexzMeta?.alias || [],
    redirect: charte_45bon_45fonctionnementRdgtL6FexzMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/charte-bon-fonctionnement.vue").then(m => m.default || m)
  },
  {
    name: editVcsCgwm8IaMeta?.name ?? "profile-edit",
    path: editVcsCgwm8IaMeta?.path ?? "/profile/edit",
    meta: editVcsCgwm8IaMeta || {},
    alias: editVcsCgwm8IaMeta?.alias || [],
    redirect: editVcsCgwm8IaMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: indexCo4AdVztaeMeta?.name ?? "profile",
    path: indexCo4AdVztaeMeta?.path ?? "/profile",
    meta: indexCo4AdVztaeMeta || {},
    alias: indexCo4AdVztaeMeta?.alias || [],
    redirect: indexCo4AdVztaeMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: missionskJnE1WIrUIMeta?.name ?? "profile-missions",
    path: missionskJnE1WIrUIMeta?.path ?? "/profile/missions",
    meta: missionskJnE1WIrUIMeta || {},
    alias: missionskJnE1WIrUIMeta?.alias || [],
    redirect: missionskJnE1WIrUIMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/missions.vue").then(m => m.default || m)
  },
  {
    name: notifications7v85q6PqU8Meta?.name ?? "profile-notifications",
    path: notifications7v85q6PqU8Meta?.path ?? "/profile/notifications",
    meta: notifications7v85q6PqU8Meta || {},
    alias: notifications7v85q6PqU8Meta?.alias || [],
    redirect: notifications7v85q6PqU8Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/notifications.vue").then(m => m.default || m)
  },
  {
    name: preferencespS8kBEUiP1Meta?.name ?? "profile-preferences",
    path: preferencespS8kBEUiP1Meta?.path ?? "/profile/preferences",
    meta: preferencespS8kBEUiP1Meta || {},
    alias: preferencespS8kBEUiP1Meta?.alias || [],
    redirect: preferencespS8kBEUiP1Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/preferences.vue").then(m => m.default || m)
  },
  {
    name: settings8MVm8RD7rDMeta?.name ?? "profile-settings",
    path: settings8MVm8RD7rDMeta?.path ?? "/profile/settings",
    meta: settings8MVm8RD7rDMeta || {},
    alias: settings8MVm8RD7rDMeta?.alias || [],
    redirect: settings8MVm8RD7rDMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/profile/settings.vue").then(m => m.default || m)
  },
  {
    name: decembre_45ensemblerhtw5Rn3rnMeta?.name ?? "quiz-decembre-ensemble",
    path: decembre_45ensemblerhtw5Rn3rnMeta?.path ?? "/quiz/decembre-ensemble",
    meta: decembre_45ensemblerhtw5Rn3rnMeta || {},
    alias: decembre_45ensemblerhtw5Rn3rnMeta?.alias || [],
    redirect: decembre_45ensemblerhtw5Rn3rnMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/decembre-ensemble.vue").then(m => m.default || m)
  },
  {
    name: generiqueZarzrFvWlAMeta?.name ?? "quiz-generique",
    path: generiqueZarzrFvWlAMeta?.path ?? "/quiz/generique",
    meta: generiqueZarzrFvWlAMeta || {},
    alias: generiqueZarzrFvWlAMeta?.alias || [],
    redirect: generiqueZarzrFvWlAMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/generique.vue").then(m => m.default || m)
  },
  {
    name: mgenctmubeWwA9Meta?.name ?? "quiz-mgen",
    path: mgenctmubeWwA9Meta?.path ?? "/quiz/mgen",
    meta: mgenctmubeWwA9Meta || {},
    alias: mgenctmubeWwA9Meta?.alias || [],
    redirect: mgenctmubeWwA9Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/mgen.vue").then(m => m.default || m)
  },
  {
    name: printemps_45pour_45la_45planeteYnYQXkFGbJMeta?.name ?? "quiz-printemps-pour-la-planete",
    path: printemps_45pour_45la_45planeteYnYQXkFGbJMeta?.path ?? "/quiz/printemps-pour-la-planete",
    meta: printemps_45pour_45la_45planeteYnYQXkFGbJMeta || {},
    alias: printemps_45pour_45la_45planeteYnYQXkFGbJMeta?.alias || [],
    redirect: printemps_45pour_45la_45planeteYnYQXkFGbJMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/printemps-pour-la-planete.vue").then(m => m.default || m)
  },
  {
    name: septembre_45pour_45apprendreGvDPXYjL1SMeta?.name ?? "quiz-septembre-pour-apprendre",
    path: septembre_45pour_45apprendreGvDPXYjL1SMeta?.path ?? "/quiz/septembre-pour-apprendre",
    meta: septembre_45pour_45apprendreGvDPXYjL1SMeta || {},
    alias: septembre_45pour_45apprendreGvDPXYjL1SMeta?.alias || [],
    redirect: septembre_45pour_45apprendreGvDPXYjL1SMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/quiz/septembre-pour-apprendre.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GkgzyWySxIMeta?.name ?? "reseaux-slug",
    path: _91slug_93GkgzyWySxIMeta?.path ?? "/reseaux/:slug()",
    meta: _91slug_93GkgzyWySxIMeta || {},
    alias: _91slug_93GkgzyWySxIMeta?.alias || [],
    redirect: _91slug_93GkgzyWySxIMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/reseaux/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVkdnaifrSxMeta?.name ?? "stats",
    path: indexVkdnaifrSxMeta?.path ?? "/stats",
    meta: indexVkdnaifrSxMeta || {},
    alias: indexVkdnaifrSxMeta?.alias || [],
    redirect: indexVkdnaifrSxMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/index.vue").then(m => m.default || m)
  },
  {
    name: missions2BEpyt20PKMeta?.name ?? "stats-missions",
    path: missions2BEpyt20PKMeta?.path ?? "/stats/missions",
    meta: missions2BEpyt20PKMeta || {},
    alias: missions2BEpyt20PKMeta?.alias || [],
    redirect: missions2BEpyt20PKMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/missions.vue").then(m => m.default || m)
  },
  {
    name: organisationsWHgs4Q0wBYMeta?.name ?? "stats-organisations",
    path: organisationsWHgs4Q0wBYMeta?.path ?? "/stats/organisations",
    meta: organisationsWHgs4Q0wBYMeta || {},
    alias: organisationsWHgs4Q0wBYMeta?.alias || [],
    redirect: organisationsWHgs4Q0wBYMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/organisations.vue").then(m => m.default || m)
  },
  {
    name: participationsiugQkO2OefMeta?.name ?? "stats-participations",
    path: participationsiugQkO2OefMeta?.path ?? "/stats/participations",
    meta: participationsiugQkO2OefMeta || {},
    alias: participationsiugQkO2OefMeta?.alias || [],
    redirect: participationsiugQkO2OefMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/participations.vue").then(m => m.default || m)
  },
  {
    name: places4r55nIrFFjMeta?.name ?? "stats-places",
    path: places4r55nIrFFjMeta?.path ?? "/stats/places",
    meta: places4r55nIrFFjMeta || {},
    alias: places4r55nIrFFjMeta?.alias || [],
    redirect: places4r55nIrFFjMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/places.vue").then(m => m.default || m)
  },
  {
    name: utilisateursAimUqYPcLWMeta?.name ?? "stats-utilisateurs",
    path: utilisateursAimUqYPcLWMeta?.path ?? "/stats/utilisateurs",
    meta: utilisateursAimUqYPcLWMeta || {},
    alias: utilisateursAimUqYPcLWMeta?.alias || [],
    redirect: utilisateursAimUqYPcLWMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/stats/utilisateurs.vue").then(m => m.default || m)
  },
  {
    name: search_45archived_45usersCganCXgv1UMeta?.name ?? "support-actions-search-archived-users",
    path: search_45archived_45usersCganCXgv1UMeta?.path ?? "/support/actions/search-archived-users",
    meta: search_45archived_45usersCganCXgv1UMeta || {},
    alias: search_45archived_45usersCganCXgv1UMeta?.alias || [],
    redirect: search_45archived_45usersCganCXgv1UMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/search-archived-users.vue").then(m => m.default || m)
  },
  {
    name: transfert_45organisationIri5szbONUMeta?.name ?? "support-actions-transfert-organisation",
    path: transfert_45organisationIri5szbONUMeta?.path ?? "/support/actions/transfert-organisation",
    meta: transfert_45organisationIri5szbONUMeta || {},
    alias: transfert_45organisationIri5szbONUMeta?.alias || [],
    redirect: transfert_45organisationIri5szbONUMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/transfert-organisation.vue").then(m => m.default || m)
  },
  {
    name: user_45reset_45context_45roleXrK9PDQqSmMeta?.name ?? "support-actions-user-reset-context-role",
    path: user_45reset_45context_45roleXrK9PDQqSmMeta?.path ?? "/support/actions/user-reset-context-role",
    meta: user_45reset_45context_45roleXrK9PDQqSmMeta || {},
    alias: user_45reset_45context_45roleXrK9PDQqSmMeta?.alias || [],
    redirect: user_45reset_45context_45roleXrK9PDQqSmMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/user-reset-context-role.vue").then(m => m.default || m)
  },
  {
    name: user_45reset_45password_45linkmU9sTNvbYeMeta?.name ?? "support-actions-user-reset-password-link",
    path: user_45reset_45password_45linkmU9sTNvbYeMeta?.path ?? "/support/actions/user-reset-password-link",
    meta: user_45reset_45password_45linkmU9sTNvbYeMeta || {},
    alias: user_45reset_45password_45linkmU9sTNvbYeMeta?.alias || [],
    redirect: user_45reset_45password_45linkmU9sTNvbYeMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/actions/user-reset-password-link.vue").then(m => m.default || m)
  },
  {
    name: doublons_45organisationsbesKoPP1rmMeta?.name ?? "support-contents-doublons-organisations",
    path: doublons_45organisationsbesKoPP1rmMeta?.path ?? "/support/contents/doublons-organisations",
    meta: doublons_45organisationsbesKoPP1rmMeta || {},
    alias: doublons_45organisationsbesKoPP1rmMeta?.alias || [],
    redirect: doublons_45organisationsbesKoPP1rmMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/contents/doublons-organisations.vue").then(m => m.default || m)
  },
  {
    name: doublons_45territoires6RGHgAxSdoMeta?.name ?? "support-contents-doublons-territoires",
    path: doublons_45territoires6RGHgAxSdoMeta?.path ?? "/support/contents/doublons-territoires",
    meta: doublons_45territoires6RGHgAxSdoMeta || {},
    alias: doublons_45territoires6RGHgAxSdoMeta?.alias || [],
    redirect: doublons_45territoires6RGHgAxSdoMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/contents/doublons-territoires.vue").then(m => m.default || m)
  },
  {
    name: indexYDWkSO1uJoMeta?.name ?? "support",
    path: indexYDWkSO1uJoMeta?.path ?? "/support",
    meta: indexYDWkSO1uJoMeta || {},
    alias: indexYDWkSO1uJoMeta?.alias || [],
    redirect: indexYDWkSO1uJoMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: activity_45logsZC4KGaDBcHMeta?.name ?? "support-referents-activity-logs",
    path: activity_45logsZC4KGaDBcHMeta?.path ?? "/support/referents/activity-logs",
    meta: activity_45logsZC4KGaDBcHMeta || {},
    alias: activity_45logsZC4KGaDBcHMeta?.alias || [],
    redirect: activity_45logsZC4KGaDBcHMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/referents/activity-logs.vue").then(m => m.default || m)
  },
  {
    name: waiting_45actionsaDvBlLQUQZMeta?.name ?? "support-referents-waiting-actions",
    path: waiting_45actionsaDvBlLQUQZMeta?.path ?? "/support/referents/waiting-actions",
    meta: waiting_45actionsaDvBlLQUQZMeta || {},
    alias: waiting_45actionsaDvBlLQUQZMeta?.alias || [],
    redirect: waiting_45actionsaDvBlLQUQZMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/referents/waiting-actions.vue").then(m => m.default || m)
  },
  {
    name: missions_45outdatedWNS2a1LHLgMeta?.name ?? "support-responsables-missions-outdated",
    path: missions_45outdatedWNS2a1LHLgMeta?.path ?? "/support/responsables/missions-outdated",
    meta: missions_45outdatedWNS2a1LHLgMeta || {},
    alias: missions_45outdatedWNS2a1LHLgMeta?.alias || [],
    redirect: missions_45outdatedWNS2a1LHLgMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/responsables/missions-outdated.vue").then(m => m.default || m)
  },
  {
    name: participations_45to_45be_45treated05eg3yBC2xMeta?.name ?? "support-responsables-participations-to-be-treated",
    path: participations_45to_45be_45treated05eg3yBC2xMeta?.path ?? "/support/responsables/participations-to-be-treated",
    meta: participations_45to_45be_45treated05eg3yBC2xMeta || {},
    alias: participations_45to_45be_45treated05eg3yBC2xMeta?.alias || [],
    redirect: participations_45to_45be_45treated05eg3yBC2xMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/support/responsables/participations-to-be-treated.vue").then(m => m.default || m)
  },
  {
    name: _91token_9344ttaaWwT6Meta?.name ?? "temoignages-token",
    path: _91token_9344ttaaWwT6Meta?.path ?? "/temoignages/:token()",
    meta: _91token_9344ttaaWwT6Meta || {},
    alias: _91token_9344ttaaWwT6Meta?.alias || [],
    redirect: _91token_9344ttaaWwT6Meta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/temoignages/[token].vue").then(m => m.default || m)
  },
  {
    name: indexK9azrK3ofPMeta?.name ?? "territoires",
    path: indexK9azrK3ofPMeta?.path ?? "/territoires",
    meta: indexK9azrK3ofPMeta || {},
    alias: indexK9azrK3ofPMeta?.alias || [],
    redirect: indexK9azrK3ofPMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/territoires/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HUUsqrUidOMeta?.name ?? "villes-slug",
    path: _91slug_93HUUsqrUidOMeta?.path ?? "/villes/:slug()",
    meta: _91slug_93HUUsqrUidOMeta || {},
    alias: _91slug_93HUUsqrUidOMeta?.alias || [],
    redirect: _91slug_93HUUsqrUidOMeta?.redirect || undefined,
    component: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/pages/villes/[slug].vue").then(m => m.default || m)
  }
]