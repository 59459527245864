export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"og:site_name","content":"JeVeuxAider.gouv.fr"},{"name":"alternateName","content":"JVA"},{"hid":"description","name":"description","content":"Trouvez une mission de bénévolat dans une association, organisation publique ou une collectivité territoriale, sur le terrain ou à distance. Plus de 18 000 missions disponibles partout en France dans 10 domaines d'action : solidarité, insertion, éducation, environnement, santé, sport, culture ..."},{"name":"format-detection","content":"telephone=no"},{}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.png"},{"rel":"sitemap","type":"application/xml","title":"Sitemap","href":"https://www.jeveuxaider.gouv.fr/sitemap.xml"}],"style":[],"script":[],"noscript":[],"title":"Devenez bénévole dans une association en quelques clics | JeVeuxAider.gouv.fr","htmlAttrs":{"lang":"fr"},"bodyAttrs":{"class":"text-base leading-normal overflow-x-hidden"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = false

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const vueAppRootContainer = '#__nuxt'