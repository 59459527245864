import validate from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/node_modules/nuxt/dist/pages/runtime/validate.js";
import maintenance_45global from "/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/maintenance.global.ts";
export const globalMiddleware = [
  validate,
  maintenance_45global
]
export const namedMiddleware = {
  admin: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/admin.ts"),
  "agreed-responsable-terms": () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/agreed-responsable-terms.ts"),
  authenticated: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/authenticated.ts"),
  guest: () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/guest.ts"),
  "old-search-redirections": () => import("/build/d25df3d6-d63b-4e04-93b5-670e866dc8d1/middleware/old-search-redirections.ts")
}